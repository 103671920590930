import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';

export default function configureStore(initialState = {}, history: any) {
    let composeEnhancers = compose;
    const reduxSagaMonitorOptions = {};

    if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
        if ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
            composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
    }

    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

    const middlewares = [sagaMiddleware, routerMiddleware(history)];

    if(process.env.NODE_ENV !== 'production') {
        middlewares.push(createLogger({collapsed: true}));
    }

    const enhancers = [applyMiddleware(...middlewares)];

    const store: any = createStore(
        createReducer(),
        initialState,
        composeEnhancers(...enhancers),
    );

    store.runSaga = sagaMiddleware.run;
    store.injectedSagas = {}; // Reducer registry
    store.injectedReducers = {}; // Reducer registry

    return store;
}
