const urlProtocol: string = process.env.NODE_ENV === "development" ? "http://" : "https://";

export const eidexApiUrl: string = `${urlProtocol}${window.location.hostname}:${window.location.port}/api`;

export const buildQueryString = (obj: object): string => {
    const parts: string[] = [];

    Object.entries(obj).forEach(property => {
        const key = encodeURIComponent(property[0]);
        const value = property[1];
        if (Array.isArray(value)) {
            parts.push(`${value.map(val => `${key}=${val}`).join('&')}`);
        } else {
        	parts.push(key + '=' + encodeURIComponent(value || ''));
        }
    });

    return '?' + parts.join('&');
};

export const buildHeadersObject = (sessionToken?: string, xsrfToken?: string): Record<string,string> => {
    let headers: Record<string,string> = {};
    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';
    if (sessionToken) headers['Authorization'] = 'Bearer ' + sessionToken;
    if (xsrfToken) headers['X-XSRF-TOKEN'] = xsrfToken;

    return headers;
};

export const getParameterByName = (name: string, url?: string): string => {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return '';
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
