const statusCodes: {[key: number]: string} = {
    200: 'OK',
    201: 'Created',
    202: 'Accepted',
    203: 'Non-Authoritative Information',
    204: 'No Content',
    205: 'Reset Content',
    206: 'Partial Content',
    300: 'Multiple Choices',
    301: 'Moved Permanently',
    302: 'Found',
    303: 'See Other',
    304: 'Not Modified',
    305: 'Use Proxy',
    306: 'Unused',
    307: 'Temporary Redirect',
    400: 'Bad Request',
    401: 'Unauthorized',
    402: 'Payment Required',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    407: 'Proxy Authentication Required',
    408: 'Request Timeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Required',
    413: 'Request Entry Too Large',
    414: 'Request-URI Too Long',
    415: 'Unsupported Media Type',
    416: 'Requested Range Not Satisfiable',
    417: 'Expectation Failed',
    418: 'I\'m a teapot',
    500: 'Internal Server Error',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: any | {status: number, json: () => JSON}): JSON | null {
    if (response.status === 204 || response.status === 205) {
        return null;
    }
    return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */

interface RequestError extends Error {
    response?: {
        status: number,
        statusText?: string
    }
};

export class UnauthorizedError extends Error {};
export class NotFoundError extends Error {};

async function checkStatus(response: Response): Promise<{status: number, statusText?: string}> {
    if(response.status < 200 || response.status >= 300) {
        const body: string = await response.text();
        const errorText: string = body || response.statusText || statusCodes[response.status];

        if(response.status === 401) {
            throw new UnauthorizedError(errorText);
        } else if(response.status === 404) {
            throw new NotFoundError("API endpoint does not exist")
        }

        const error: RequestError = new Error(errorText);
        error.response = response;

        throw error;
    }

    return response;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url?: string, options?: object): object {
    if(process.env.NODE_ENV === 'development') {
        console.log(options);
    }

    return fetch(url as string, options)
        .then(checkStatus)
        .then(parseJSON);
}
