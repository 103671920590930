import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

interface Props {
    style?: {},
    label: string,
    value: string
};

const useStyles = makeStyles(theme => ({
    container: (props: Props) => ({
        padding: "10px 30px 10px 0px",
        minWidth: "130px",
        ...props.style
    }),
    label: {
        color: theme.palette.primaryFade.main,
        fontSize: "14px"
    },
    value: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));

const LabeledText: React.FC<Props> = (props) => {
    const classes = useStyles(props);
    const { label, value } = props;

    return (
        <div className={classes.container}>
            <Typography className={classes.label}>{label}:</Typography>
            <Typography className={classes.value}>{value}</Typography>
        </div>
    );
}

export default LabeledText;