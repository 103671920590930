import { call, put, takeLatest } from 'redux-saga/effects';

import AuthService, { InvalidJwtError } from '../../services/AuthService';
import request, { UnauthorizedError } from '../../utils/request';
import { eidexApiUrl, buildHeadersObject } from '../../utils/apiUrlUtils';
import { RAISE_ALERT, REDIRECT_TO_LOGIN } from '../App/constants';
import { FocusVersion } from '../App/types';

import { GET_QV_DOC, SET_QV_DOC } from './constants';

export function* getQvDoc(action: { type: string, focusVersion: FocusVersion }) {
    try {
        const sessionToken: string = AuthService.validToken;

        const qvDoc: string = yield call(request, `${eidexApiUrl}/qlik/qvDoc/${action.focusVersion}`, {method: 'GET', headers: buildHeadersObject(sessionToken)});
        yield put({type: SET_QV_DOC, value: qvDoc});
    } catch (error) {
        if(error instanceof InvalidJwtError || error instanceof UnauthorizedError) {
            yield put({type: REDIRECT_TO_LOGIN});
        } else {
            // @ts-ignore
            yield put({type: RAISE_ALERT, alertType: 'error', message: yield error.message});
        }
    }
}

export default function* appFrameWatcher() {
    yield takeLatest(GET_QV_DOC, getQvDoc);
}
