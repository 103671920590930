import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

type Props = {
    readonly open: boolean,
    readonly onClose: () => void,
    readonly title: string,
    readonly topRightClose?: boolean,
    readonly fullWidth: boolean,
    readonly maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    readonly disableBackdropClick?: boolean,
    style?: object
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeButton: {
            float: "right",
            padding: "8px 10px 0 10px",
            marginTop: "-4px",
            cursor: "pointer",
            borderRadius: "5px",

            "&:hover": {
                background: "#ffffff1a"
            }
        }
    })
);


const ModalDialog: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={props.fullWidth} maxWidth={props.maxWidth} disableBackdropClick={props.disableBackdropClick}>
            <DialogTitle style={props.style ? props.style : {}}>
                {props.title}
                {props.topRightClose &&
                    <div className={classes.closeButton} onClick={props.onClose}>
                        <CloseIcon/>
                    </div>}
            </DialogTitle>
            {props.children}
        </Dialog>
    );
};

export default ModalDialog;