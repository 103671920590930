import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeStyles } from "@material-ui/core/styles";

import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import { makeSelectFocusVersion } from '../App/selectors';
import { FocusVersion } from '../App/types';

import saga from './saga';
import reducer from './reducer';
import { getQvDoc } from './actions';
import { makeSelectIsWaiting, makeSelectQvDoc } from './selectors';
import LoadingIndicator from '../../components/LoadingIndicator';

const useStyles = makeStyles(theme => ({
    qvFrame: {
        clear: "left",
        margin: "0 auto",
        width: "1280px",
        height: "870px",
        display: "block",
        border: "0px",
        overflow: "hidden"
    }
}));

type Props = {
    readonly isWaiting: boolean,
    readonly qvDoc: string,
    readonly focusVersion: FocusVersion,
    readonly getQvDoc: (focusVersion: FocusVersion) => void
};

const key = 'focusFrame';

const FocusFrame: React.FC<Props> = (props) => {
    const classes = useStyles();

    useInjectReducer(key, reducer);
    useInjectSaga(key, saga);

    const { isWaiting, qvDoc, focusVersion, getQvDoc } = props;

    useEffect(() => {
        getQvDoc(focusVersion);
    }, [focusVersion, getQvDoc]);

    return (
        isWaiting
            ? <LoadingIndicator />
            : <iframe title="Eidex Focus" id="QlikViewContainer" className={classes.qvFrame} src={qvDoc} />
    );
}

const mapStateToProps = createStructuredSelector({
	isWaiting: makeSelectIsWaiting(),
    qvDoc: makeSelectQvDoc(),
    focusVersion: makeSelectFocusVersion()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getQvDoc: (focusVersion: FocusVersion) => dispatch(getQvDoc(focusVersion))
});

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps
);

export default withConnect(FocusFrame);