import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AppHeader from '../../components/AppFrame/AppHeader';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import { logout } from '../App/actions';
import {
    makeSelectInitials
} from '../App/selectors';

import saga from './saga';
import reducer from './reducer';

type Props = {
    readonly logout: () => void,
    readonly userInitials: string
};

const key = 'appFrame';

const AppFrame: React.FC<Props> = (props) => {
    useInjectReducer(key, reducer);
    useInjectSaga(key, saga);

    const { logout, userInitials  } = props;

    return <AppHeader userInitials={userInitials} logout={logout} />;
}

const mapStateToProps = createStructuredSelector({
    userInitials: makeSelectInitials()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(logout())
});

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps
);

export default withConnect(AppFrame);