import { Credential, CredentialType } from '../App/types';

import { OPEN_PASSWORD_CHANGE_MODAL, CLOSE_PASSWORD_CHANGE_MODAL, CHANGE_PASSWORD, FETCH_CRED_LABELS, UPDATE_USER_CRED_LABELS } from './constants';
import { CredentialOptionsLookUp } from './types';

export const openPasswordChangeModal = () => ({
    type: OPEN_PASSWORD_CHANGE_MODAL
});

export const closePasswordChangeModal = () => ({
    type: CLOSE_PASSWORD_CHANGE_MODAL
});

export const changePassword = (oldPassword: string, newPassword: string, confirm: string) => ({
    type: CHANGE_PASSWORD,
    oldPassword: oldPassword,
    newPassword: newPassword,
    confirm: confirm
});

export const getUserCredLabels = (credType: CredentialType, creds: Credential[]) => ({
    type: FETCH_CRED_LABELS,
    credType,
    creds
});

export const updateUserCredLabels = (credType: CredentialType, options: CredentialOptionsLookUp) => ({
    type: UPDATE_USER_CRED_LABELS,
    credType,
    options
});
