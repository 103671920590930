import React from 'react'
import { transitions, positions } from 'react-alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

/**
 * React alert template
 *
 * This is for the AlertProvider wrapper in the root file
 * https://github.com/schiehll/react-alert
 *
 * Takes two params, (String: message, {type: 'info' or 'success' or 'error'})
 *
 * Hooks/functional
 * import { useAlert } from 'react-alert'
 * const alert = useAlert()
 */

type Props = {
    readonly style: any,
    readonly options: any,
    readonly message: any,
    readonly close: any
}

const alertStyle = {
    color: 'white',
    padding: '10px',
    textTransform: 'capitalize',
    fontSize: '18px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 5px 5px -1px rgba(0,0,0,0.2)',
    fontFamily: 'Roboto, Helvetica, Arial',
    width: '415px',
    boxSizing: 'border-box'
};

export const AlertConfig = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE,
    containerStyle: {
        zIndex: 2000
    }
};

export const AlertTemplate: React.FC<Props> = (props) => {
    const theme = useTheme();
    const { style, options, message, close } = props;

    return (
        <div
            style={options.type === 'success'
                ? {...alertStyle, backgroundColor: theme.palette.success.main, ...style}
                : options.type === 'error'
                    ? {...alertStyle, backgroundColor: theme.palette.error.main, ...style}
                    : {...alertStyle, backgroundColor: theme.palette.info.main, ...style}
            }
        >
            {options.type === 'info' && <InfoIcon style={{marginRight: '20px'}} />}
            {options.type === 'success' && <CheckCircleIcon style={{marginRight: '20px'}}/>}
            {options.type === 'error' && <ErrorIcon style={{marginRight: '20px'}}/>}
            <span style={{ flex: 2, textTransform: "none" }}>{message}</span>
            <Button style={{color: 'white'}} onClick={close}>
                <CloseIcon />
            </Button>
        </div>
    )
}

export default AlertTemplate;