import produce from 'immer';

import { SET_CHECKING_STATUS, SET_SESSION_INFO, SET_ACTIVE_SESSION, SET_FOCUS_VERSION, RAISE_ALERT, CLEAR_ALERT } from './constants';
import { InitialState } from './types';

export const initialState: InitialState = {
    focusVersion: 2,
    checkingSession: false,
    sessionInfo: {
        hasActiveSession: false,
        xsrfToken: '',
        user: {
            userId: '',
            accountId: '',
            roleId: '',
            pendingInvite: false,
            accessLevel: 'U',
            firstName: '',
            lastName: '',
            email: '',
            consecutiveFailedLogins: 0,
            lockedOutUntilUtc: null,
            lastLoginUtc: null,
            prismCredentials: [],
            focusCredentials: []
        },
        account: {
            accountId: '',
            accountName: '',
            csmId: '',
            startDate: null,
            endDate: null,
            prismCredentials: [],
            focusCredentials: []
        },
        csm: {
            csmId: '',
            name: '',
            email: '',
            phoneNumber: '',
            imageFileName: '',
            calendarLink: ''
        },
        licenses: []
    },
    alert: {
        show: false,
        message: '',
        type: undefined
    }
};

const appReducer = (state = initialState, action: any) =>
    produce(state, draft => {
        switch (action.type) {
            case SET_CHECKING_STATUS: {
                draft.checkingSession = action.value;
                break;
            }
            case SET_SESSION_INFO: {
                draft.sessionInfo = action.sessionInfo;
                break;
            }
            case SET_ACTIVE_SESSION: {
                draft.sessionInfo.hasActiveSession = action.hasActiveSession;
                break;
            }
            case SET_FOCUS_VERSION: {
                draft.focusVersion = action.version;
                break;
            }
            case RAISE_ALERT: {
                draft.alert.show = true;
                draft.alert.message = action.message;
                draft.alert.type = action.alertType;
                break;
            }
            case CLEAR_ALERT: {
                draft.alert.show = false;
                draft.alert.message = '';
                draft.alert.type = undefined;
                break;
            }
            default: {
                break;
            }
        }
    });

export default appReducer;