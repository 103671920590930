import Cookies from 'universal-cookie';

import * as jwtHelper from '../utils/jwtHelper';

export class InvalidJwtError extends Error {
    constructor() {
        super("Session has expired. Re-directing to login page.")
    }
}

class AuthService {
    cookie: Cookies;
    cookieNames: {[key: string]: string};

    constructor() {
        this.cookie = new Cookies();
        this.cookieNames = {
            eidexSession: "Eidex.Session",
            refreshToken: "Eidex.RefreshToken"
        }
    }

    get sessionToken(): string {
        return this.cookie.get(this.cookieNames.eidexSession) || null;
    }

    get refreshToken(): string {
        return this.cookie.get(this.cookieNames.refreshToken) || null;
    }

    get validToken(): string {
        if(this.sessionToken) {
            const loginData = jwtHelper.getLoginData(this.sessionToken);

            if (loginData?.type === "eidex" && !loginData?.isTokenExpired) {
                return this.sessionToken;
            }
        }

        throw new InvalidJwtError();
    }

    get isLoggedIn(): boolean {
        try {
            return !!this.validToken;
        } catch(error) {
            if(error instanceof InvalidJwtError) {
                return false;
            } else {
                throw error;
            }
        }
    }

    clearCookies(): void {
        this.cookie.remove(this.cookieNames.eidexSession);
        this.cookie.remove(this.cookieNames.refreshToken);
    }
}

export default new AuthService();
