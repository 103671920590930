import { call, select, put, takeLatest, takeEvery, all } from 'redux-saga/effects';

import AuthService, { InvalidJwtError } from '../../services/AuthService';
import request, { UnauthorizedError } from '../../utils/request';
import { eidexApiUrl, buildHeadersObject, buildQueryString } from '../../utils/apiUrlUtils';
import checkPassword from '../../utils/checkPassword';
import { makeSelectXSRF } from '../App/selectors';
import { RAISE_ALERT, REDIRECT_TO_LOGIN } from '../App/constants';
import { Credential, CredentialType, CredentialField } from '../App/types';

import { updateUserCredLabels } from './actions';
import { CHANGE_PASSWORD, CLOSE_PASSWORD_CHANGE_MODAL, FETCH_CRED_LABELS } from './constants';
import { CredentialOptionsLookUp } from './types';

const xsrfSelector = makeSelectXSRF();

export function* changePassword(action: { type: string, oldPassword: string, newPassword: string, confirm: string }) {
    try {
        const sessionToken: string = AuthService.validToken;
        const xsrfToken: string = yield select(xsrfSelector);

        const { oldPassword, newPassword, confirm } = action;

        if(oldPassword !== "" && Object.values(checkPassword(newPassword)).every(v => v) && newPassword === confirm) {
            const data = {
                oldPassword: oldPassword,
                newPassword: newPassword
            }

            yield call(request, `${eidexApiUrl}/user/changePassword`, {method: 'PUT', headers: buildHeadersObject(sessionToken, xsrfToken), body: JSON.stringify(data)});
            yield put({type: CLOSE_PASSWORD_CHANGE_MODAL});
            yield put({type: RAISE_ALERT, alertType: 'success', message: `Password changed`});
        } else {
            yield put({type: RAISE_ALERT, alertType: 'error', message: 'Error in password form'});
        }
    } catch (error) {
        if(error instanceof InvalidJwtError || error instanceof UnauthorizedError) {
            yield put({type: REDIRECT_TO_LOGIN});
        } else {
            // @ts-ignore
            yield put({type: RAISE_ALERT, alertType: 'error', message: yield error.message});
        }
    }
}

// Get the labels for each value in all of the user's Focus or Prism Credentials
export function* getUserCredLabels(action: { type: string, credType: CredentialType, creds: Credential[] }) {
    try {
        const sessionToken: string = AuthService.validToken;
        const xsrfToken: string = yield select(xsrfSelector);

        const urlPrefix: string = `${eidexApiUrl}/credential`;
        const urlOptions: any = {method: 'GET', headers: buildHeadersObject(sessionToken, xsrfToken)};
        let fetchRequests: { [key: string]: unknown } = {};

        action.creds.forEach( (cred: Credential, credNum: number) => {
            let query: Credential = cleanCredentialQuery(cred);

            const queryCredFields: Array<CredentialField> = (Object.keys(query) as Array<CredentialField>);

            queryCredFields.forEach( (field: CredentialField, i: number) => {
                let newQuery: Credential = {};

                // Collect all (keys: value) pairs up to the current field (i)
                queryCredFields.slice(0, (i+1)).forEach( key => {
                    newQuery = {...newQuery, [key]: query[key]}
                })

                fetchRequests[`${field}_${credNum}`] = call(request, `${urlPrefix}/${buildQueryString({credType: action.credType, field, ...newQuery})}`, urlOptions);
            })
        })

        // @ts-ignore
        const results: CredentialOptionsLookUp = yield all(fetchRequests);
        yield put(updateUserCredLabels(action.credType, results));

    } catch (error) {
        if(error instanceof InvalidJwtError || error instanceof UnauthorizedError) {
            yield put({type: REDIRECT_TO_LOGIN});
        } else {
            // @ts-ignore
            yield put({type: RAISE_ALERT, alertType: 'error', message: yield error.message});
        }
    }
}

// Remove any null or undefined CredentialFields from the query
const cleanCredentialQuery = (query: Credential) => {
    const cleanQuery: Credential = {};

    (Object.keys(query) as Array<CredentialField>).forEach( (key: CredentialField) => {
        if (query[key]) {
            cleanQuery[key] = query[key];
        }
    })

    return cleanQuery;
}

export default function* appFrameWatcher() {
    yield takeLatest(CHANGE_PASSWORD, changePassword);
    yield takeEvery(FETCH_CRED_LABELS, getUserCredLabels);
}
