import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import AuthService from '../../services/AuthService';
import request, { UnauthorizedError } from '../../utils/request';
import { eidexApiUrl, buildHeadersObject } from '../../utils/apiUrlUtils';

import { SET_CHECKING_STATUS, GET_SESSION_INFO, SET_SESSION_INFO, REDIRECT_TO_LOGIN, RAISE_ALERT, LOGOUT, SET_ACTIVE_SESSION } from './constants';
import { makeSelectXSRF } from './selectors';
import { SessionInfo } from './types';

export function* fetchCurrentSession() {
    try {
        const sessionToken: string = AuthService.sessionToken;
        const refreshToken: string = AuthService.refreshToken;

        if (!!sessionToken || !!refreshToken) {
            yield put({type: SET_CHECKING_STATUS, value: true});

            const response = !!sessionToken
                // @ts-ignore
                ? yield call(request, `${eidexApiUrl}/session`, {method: 'GET', headers: buildHeadersObject(sessionToken)})
                // @ts-ignore
                : yield call(request, `${eidexApiUrl}/session`, {method: 'POST', headers: buildHeadersObject(), body: JSON.stringify({refreshToken: refreshToken})});

            const sessionInfo: SessionInfo = {
                xsrfToken: response.xsrfToken,
                user: response.user,
                account: response.account,
                csm: response.csm,
                licenses: response.licenses,
                hasActiveSession: true
            };

            yield put({type: SET_SESSION_INFO, sessionInfo: sessionInfo});
            yield put({type: SET_CHECKING_STATUS, value: false});
        }
    } catch (error) {
        yield put({type: SET_CHECKING_STATUS, value: false});

        if(error instanceof UnauthorizedError) {
            yield put({type: REDIRECT_TO_LOGIN});
        } else {
            // @ts-ignore
            yield put({type: RAISE_ALERT, alertType: 'error', message: yield error.message});
            yield put(replace('/login'));
        }
    }
}

export function* logout() {
    const sessionToken: string = AuthService.sessionToken;
    const xsrfToken: string = yield select(makeSelectXSRF());

    yield put({type: SET_ACTIVE_SESSION, hasActiveSession: false});
    yield call(request, `${eidexApiUrl}/session`, {method: 'DELETE', headers: buildHeadersObject(sessionToken, xsrfToken)});
    yield put(replace('/login'));
}

export function* redirectToLogin() {
    AuthService.clearCookies();

    yield put({type: SET_ACTIVE_SESSION, hasActiveSession: false});
    yield put({type: RAISE_ALERT, alertType: 'error', message: "Session has expired. Re-directing to login page."});
    setTimeout(() => put(replace('/login')), 3000);
}

export default function* AppWatcher() {
    yield takeLatest(GET_SESSION_INFO, fetchCurrentSession);
    yield takeEvery(LOGOUT, logout);
    yield takeLatest(REDIRECT_TO_LOGIN, redirectToLogin);
}
