import produce from 'immer';

import { SET_IS_WAITING, GET_QV_DOC, SET_QV_DOC } from './constants';
import { InitialState } from './types';

export const initialState: InitialState = {
    isWaiting: false,
    qvDoc: ""
};

const focusFrameReducer = (state = initialState, action: any) =>
    produce(state, draft => {
        switch (action.type) {
            case SET_IS_WAITING: {
                draft.isWaiting = action.value;
                break;
            }
            case GET_QV_DOC: {
                draft.isWaiting = true;
                break;
            }
            case SET_QV_DOC: {
                draft.qvDoc = action.value
                draft.isWaiting = false;
                break;
            }
            default: {
                break;
            }
        }
    });

export default focusFrameReducer;