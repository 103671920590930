import React, { useState, useEffect } from 'react';
import { RouteProps, Redirect } from 'react-router-dom';

import LoadingIndicator from '../../components/LoadingIndicator';

interface Props extends RouteProps {
    hasAccess?: boolean | null
}

const AccessWrapper: React.FC<Props> = (props) => {
    const [isRedirect, setIsRedirect] = useState(false);
    const [uncertain, setUncertain] = useState(true);

    const { component, hasAccess, ...rest } = props;

    useEffect(() => {
        if (hasAccess !== undefined) {
            if (hasAccess && hasAccess !== null) {
                setUncertain(false);
            } else if (hasAccess !== null && !hasAccess) {
                setIsRedirect(true);
            }
        }

        setUncertain(false);
    }, [hasAccess]);

    const Component = component as React.FC<any>;

    return (
        uncertain ? <LoadingIndicator />
        : isRedirect ? <Redirect to={{pathname: "/login", state: { from: rest.location }}} />
        : <Component {...rest} />
    );
}

export default AccessWrapper;