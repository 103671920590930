import { PasswordRequirements } from '../containers/AppFrame/types';

export const checkPassword = (password: string): PasswordRequirements => ({
    length: password.length >= 8 && password.length <= 40,
    uppercase: password.search(/[A-Z]/g) !== -1,
    lowercase: password.search(/[a-z]/g) !== -1,
    numeric: password.search(/[0-9]/g) !== -1,
    symbol: password.search(/[^A-Za-z0-9\s]/g) !== -1
});

export default checkPassword;