import React, { memo, useState, useRef } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import Logo from '../../assets/images/Eidex_logo_whiteText_LightBlueDot.svg';
import checkPassword from '../../utils/checkPassword';
import { changePassword } from '../../containers/AppFrame/actions';
import { requirementMessages } from '../../containers/AppFrame/constants';
import { PasswordRequirementType, PasswordRequirements } from '../../containers/AppFrame/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            padding: theme.spacing(3, 2)
        },
        logo: {
            width: '198.01px',
            height: '36.56px',
            margin: '0 auto',
            display: 'block'
        },
        wrapper: {
            position: 'relative',
            width: '100%',
        },
        requirements: {
            fontSize: '9pt',
            width: "fit-content",
            margin: "auto"
        },
        reqIcon: {
            fontSize: 'inherit',
            verticalAlign: 'middle'
        },
        inputs: {
            marginTop: theme.spacing(1)
        }
    })
);

interface Props {
    changePassword: (oldPassword: string, newPassword: string, confirm: string) => void
};

const PasswordUpdatePane: React.FC<Props> = (props) => {
    const classes = useStyles(props);

    const { changePassword } = props;

    const oldPasswordInput = useRef<HTMLInputElement>(null);
    const newPasswordInput = useRef<HTMLInputElement>(null);
    const confirmInput = useRef<HTMLInputElement>(null);
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [confirmMatches, setConfirmMatches] = useState<boolean>(false);
    const [passwordReqs, setPasswordReqs] = useState<PasswordRequirements>({
        length: false,
        uppercase: false,
        lowercase: false,
        numeric: false,
        symbol: false
    });

    const meetsRequirements = Object.keys(passwordReqs).every(key => passwordReqs[key as PasswordRequirementType]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const oldPassword: string = oldPasswordInput.current ? oldPasswordInput.current.value : "";
        const newPassword: string = newPasswordInput.current ? newPasswordInput.current.value : "";
        const confirm: string = confirmInput.current ? confirmInput.current.value : "";

        changePassword(oldPassword, newPassword, confirm);
    }

    const handleNewPasswordChange = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
        const newPassword: string = newPasswordInput.current ? newPasswordInput.current.value : "";
        const confirm: string = confirmInput.current ? confirmInput.current.value : "";

        setPasswordReqs(checkPassword(newPassword));
        setConfirmMatches(newPassword === confirm);
    }, 1000);

    const handleConfirmChange = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
        const newPassword: string = newPasswordInput.current ? newPasswordInput.current.value : "";
        const confirm: string = confirmInput.current ? confirmInput.current.value : "";

        setConfirmMatches(newPassword === confirm);
    }, 1000);

    return (
        <Container maxWidth="xs">
            <Card style={{boxShadow: "none"}}>
                <Box className={classes.header} style={{display: "none"}} bgcolor="secondary.main">
                    <img src={Logo} className={classes.logo} alt="Eidex Logo"/>
                </Box>
                <form onSubmit={handleSubmit}>
                    <CardContent>
                        <div className={classes.requirements}>
                            {Object.keys(passwordReqs).map(key => (
                                <div key={key} style={{width: 'fit-content', color: passwordReqs[key as PasswordRequirementType] ? "green" : "red"}}>
                                    <span>
                                        {passwordReqs[key as PasswordRequirementType]
                                            ? <CheckIcon className={classes.reqIcon} />
                                            : <ClearIcon className={classes.reqIcon} />}
                                    </span>
                                    {requirementMessages[key as PasswordRequirementType]}
                                </div>
                            ))}
                        </div>
                        <FormGroup className={classes.inputs}>
                            <FormControl>
                                <InputLabel htmlFor="oldPassword">Old Password</InputLabel>
                                <Input
                                    id="oldPassword"
                                    type={showOldPassword ? "text" : "password"}
                                    inputRef={oldPasswordInput}
                                    required
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                size="small"
                                                onMouseDown={() => setShowOldPassword(true)}
                                                onMouseUp={() => setShowOldPassword(false)}
                                            >
                                                {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </FormGroup>
                        <FormGroup className={classes.inputs}>
                            <FormControl>
                                <InputLabel htmlFor="newPassword">New Password</InputLabel>
                                <Input
                                    id="newPassword"
                                    type={showNewPassword ? "text" : "password"}
                                    inputRef={newPasswordInput}
                                    required
                                    onChange={handleNewPasswordChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                size="small"
                                                onMouseDown={() => setShowNewPassword(true)}
                                                onMouseUp={() => setShowNewPassword(false)}
                                            >
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </FormGroup>
                        <FormGroup className={classes.inputs}>
                        <FormControl error={!!confirmInput.current && confirmInput.current.value !== "" && !confirmMatches}>
                                <InputLabel htmlFor="confirm">Confirm Password</InputLabel>
                                <Input
                                    id="confirm"
                                    type={showConfirm ? "text" : "password"}
                                    inputRef={confirmInput}
                                    required
                                    onChange={handleConfirmChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                size="small"
                                                onMouseDown={() => setShowConfirm(true)}
                                                onMouseUp={() => setShowConfirm(false)}
                                            >
                                                {showConfirm ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {(!!confirmInput.current && confirmInput.current.value !== "" && !confirmMatches) && <FormHelperText>Passwords do not match</FormHelperText>}
                            </FormControl>
                        </FormGroup>
                    </CardContent>
                    <CardActions>
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                disabled={(oldPasswordInput.current && oldPasswordInput.current.value === "") || !meetsRequirements || !confirmMatches}
                                type="submit"
                            >
                                Reset Password
                            </Button>
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Container>
    );
}

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    changePassword: (oldPassword: string, newPassword: string, confirm: string) => dispatch(changePassword(oldPassword, newPassword, confirm))
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
);

export default withConnect(memo(PasswordUpdatePane));