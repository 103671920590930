import { PasswordRequirementMessages } from './types';

export const OPEN_PASSWORD_CHANGE_MODAL = 'containers/AppFrame/OPEN_PASSWORD_CHANGE_MODAL';
export const CLOSE_PASSWORD_CHANGE_MODAL = 'containers/AppFrame/CLOSE_PASSWORD_CHANGE_MODAL';
export const CHANGE_PASSWORD = 'containers/AppFrame/CHANGE_PASSWORD';
export const FETCH_CRED_LABELS = 'containers/AppFrame/FETCH_CRED_LABELS';
export const UPDATE_USER_CRED_LABELS = 'containers/AppFrame/UPDATE_USER_CRED_LABELS';

export const IntermediateLabels: {[key: string]: string} = {
    MI: "ISD",
    PA: "IU",
    TX: "ESC Region",
    NJ: "County",
    NY: "BOCES"
}

export const requirementMessages: PasswordRequirementMessages = {
    length: "Must be between 8-40 characters",
    uppercase: "Must contain at least one uppercase character",
    lowercase: "Must contain at least one lowercase character",
    numeric: "Must contain at least one number",
    symbol: "Must contain at least one symbol"
};