import { createSelector } from 'reselect';

import { initialState } from './reducer';
import { InitialState } from './types';

const selectAppFrame = (state: any) => state.appFrame || initialState;

const makeSelectPasswordChangeModalIsOpen = () => createSelector(
    [selectAppFrame],
    (appFrameState: InitialState) => appFrameState.passwordChangeModalIsOpen
);

const makeSelectUserPrismCredLabels= () => createSelector(
    [selectAppFrame],
    (appFrameState: InitialState) => appFrameState.userPrismCredLabels
);

const makeSelectUserFocusCredLabels = () => createSelector(
    [selectAppFrame],
    (appFrameState: InitialState) => appFrameState.userFocusCredLabels
);

export { 
    makeSelectPasswordChangeModalIsOpen,
    makeSelectUserPrismCredLabels,
    makeSelectUserFocusCredLabels
};
