import { createSelector } from 'reselect';

import { initialState } from './reducer';
import { InitialState, SessionInfo, CsmInfo, User, Credential } from './types';

const selectAppState = (state: any) => state.app || initialState;

const makeSelectFocusVersion = () => createSelector(
    [selectAppState],
    (appState: InitialState) => appState.focusVersion
);

const makeSelectCheckingSession = () => createSelector(
    [selectAppState],
    (appState: InitialState) => appState.checkingSession
);

const makeSelectSessionInfo = () => createSelector(
    [selectAppState],
    (appState: InitialState) => appState.sessionInfo
);

const makeSelectAlert = () => createSelector(
    [selectAppState],
    (appState: InitialState) => appState.alert
);

const makeSelectXSRF = () => createSelector(
    [selectSessionInfo],
    (sessionInfo: SessionInfo) => sessionInfo.xsrfToken
);

const makeSelectCurrentUser = () => createSelector(
    [selectSessionInfo],
    (sessionInfo: SessionInfo) => sessionInfo.user
);

const makeSelectLicenses = () => createSelector(
    [selectSessionInfo],
    (sessionInfo: SessionInfo) => sessionInfo.licenses
);

const makeSelectHasActiveSession = () => createSelector(
    [selectSessionInfo],
    (sessionInfo: SessionInfo) => sessionInfo.hasActiveSession
);

const makeSelectIsEidexAdmin = () => createSelector(
    [selectCurrentUser],
    (currentUser: User) => currentUser.accessLevel === 'E'
);

const makeSelectIsEidexStaff = () => createSelector(
    [selectIsEidexAdmin, selectCurrentUser],
    (isEidexAdmin: boolean, currentUser: User) => isEidexAdmin || currentUser.accessLevel === 'S'
);

const makeSelectIsAccountAdmin = () => createSelector(
    [selectIsEidexStaff, selectCurrentUser],
    (isEidexStaff: boolean, currentUser: User) => isEidexStaff || currentUser.accessLevel === 'A'
);

const makeSelectIsDataAdmin = () => createSelector(
    [selectIsAccountAdmin, selectCurrentUser],
    (isAccountAdmin: boolean, currentUser: User) => isAccountAdmin || currentUser.accessLevel === 'D'
);

const makeSelectAccessLevelName = () => createSelector(
    [selectIsEidexAdmin, selectIsEidexStaff, selectIsAccountAdmin, selectIsDataAdmin],
    (isEidexAdmin: boolean, isEidexStaff: boolean, isAccountAdmin: boolean, isDataAdmin: boolean) =>
        isEidexAdmin ? "Eidex Admin"
        : isEidexStaff ? "Eidex Staff"
        : isAccountAdmin ? "Account Admin"
        : isDataAdmin ? "Data Admin"
        : "User"
);

const makeSelectUserStates = () => createSelector(
    [selectCurrentUser],
    (currentUser: User) => {
        const uniqueStates: string[] = [];

        currentUser.prismCredentials.forEach((cred: Credential) => {
            if(cred.state && !uniqueStates.includes(cred.state)) {
                uniqueStates.push(cred.state);
            }
        });

        currentUser.focusCredentials.forEach((cred: Credential) => {
            if(cred.state && !uniqueStates.includes(cred.state)) {
                uniqueStates.push(cred.state);
            }
        });

        return uniqueStates;
    }
);

const makeSelectCsmInfo = () => createSelector(
    [selectSessionInfo],
    (sessionInfo: SessionInfo) => sessionInfo.csm
);

const makeSelectCsmName = () => createSelector(
    [selectCsmInfo],
    (csmInfo: CsmInfo) => csmInfo.name
);

const makeSelectAccountId = () => createSelector(
    [selectSessionInfo],
    (sessionInfo: SessionInfo) => sessionInfo.account.accountId
);

const makeSelectPrismAccess = () => createSelector(
    [selectSessionInfo],
    (sessionInfo: SessionInfo) => !!sessionInfo.licenses?.find(license => license.type === 'full' || license.type === 'prism')
        && (sessionInfo.user?.accessLevel === "E" || !!sessionInfo.user?.prismCredentials.length)
);

const makeSelectFocusAccess = () => createSelector(
    [selectSessionInfo],
    (sessionInfo: SessionInfo) => !!sessionInfo.licenses?.find(license => license.type === 'full' || license.type === 'focus')
        && (sessionInfo.user?.accessLevel === "E" || !!sessionInfo.user?.focusCredentials.length)
);

const makeSelectLicensesLabel = () => createSelector(
    [selectSessionInfo],
    (sessionInfo: SessionInfo) =>
        sessionInfo.licenses.length
        ? sessionInfo.licenses.map(license =>
            license.type === 'full' ? "Full" :
            license.type === 'prism' ? "PRISM" :
            license.type === 'focus' ? "FOCUS" : null).join(", ")
        : "No Licenses Found"
);

const makeSelectFullName = () => createSelector(
    [selectCurrentUser],
    (currentUser: User) => {
        const hasFirstName = currentUser.firstName !== "";
        const hasLastName = currentUser.lastName !== "";
        return `${hasFirstName ? currentUser.firstName : ""}` +
               `${hasFirstName && hasLastName ? " " : ""}` + // Add space if there is both a first and last name
               `${hasLastName ? currentUser.lastName : ""}` +
               `${hasFirstName || hasLastName ? "" : `User ${currentUser.userId}`}` // Use "user ##" if there is neither a first or last name
    }
);

const makeSelectInitials = () => createSelector(
    [selectFullName],
    (fullName: string) =>
        /^User \d*$/.test(fullName)
        ? fullName.split(" ")[1]
        : fullName.split(" ").map(name => name[0]).join("")
);

const selectSessionInfo = makeSelectSessionInfo();
const selectCurrentUser = makeSelectCurrentUser();
const selectFullName = makeSelectFullName();
const selectCsmInfo = makeSelectCsmInfo();
const selectIsEidexAdmin = makeSelectIsEidexAdmin();
const selectIsEidexStaff = makeSelectIsEidexStaff();
const selectIsAccountAdmin = makeSelectIsAccountAdmin();
const selectIsDataAdmin = makeSelectIsDataAdmin();

export {
    makeSelectFocusVersion,
    makeSelectCheckingSession,
    makeSelectXSRF,
    makeSelectAlert,
    makeSelectCurrentUser,
    makeSelectLicenses,
    makeSelectHasActiveSession,
    makeSelectIsEidexAdmin,
    makeSelectIsEidexStaff,
    makeSelectIsAccountAdmin,
    makeSelectIsDataAdmin,
    makeSelectAccessLevelName,
    makeSelectUserStates,
    makeSelectCsmInfo,
    makeSelectCsmName,
    makeSelectAccountId,
    makeSelectFocusAccess,
    makeSelectPrismAccess,
    makeSelectLicensesLabel,
    makeSelectFullName,
    makeSelectInitials
};
