import { createSelector } from 'reselect';

import { initialState } from './reducer';
import { InitialState } from './types';

const selectFocusFrame = (state: any) => state.focusFrame || initialState;

const makeSelectIsWaiting = () => createSelector(
    [selectFocusFrame],
    (focusFrameState: InitialState) => focusFrameState.isWaiting
);

const makeSelectQvDoc = () => createSelector(
    [selectFocusFrame],
    (focusFrameState: InitialState) => focusFrameState.qvDoc
);

export {
    makeSelectIsWaiting,
    makeSelectQvDoc
};
