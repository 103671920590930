import { AlertType } from 'react-alert';
import { GET_SESSION_INFO, SET_FOCUS_VERSION, REDIRECT_TO_LOGIN, RAISE_ALERT, CLEAR_ALERT, LOGOUT } from './constants';
import { FocusVersion } from './types';

export const getCurrentSession = () => ({
    type: GET_SESSION_INFO
});

export const setFocusVersion = (version: FocusVersion) => ({
    type: SET_FOCUS_VERSION,
    version
});

export const redirectToLogin = () => ({
    type: REDIRECT_TO_LOGIN
});

export const raiseAlert = (alertType: AlertType, message: string) => ({
    type: RAISE_ALERT,
    alertType,
    message
});

export const clearAlert = () => ({
    type: CLEAR_ALERT
});

export const logout = () => ({
    type: LOGOUT
});
