import decode from 'jwt-decode';

class LoginData {
    type: "eidex" | "micip";
    expirationDateTime: Date;
    email: string;
    userId: string;

    constructor(type: "eidex" | "micip", expirationDateTime: Date, email: string, userId: string) {
        this.type = type;
        this.expirationDateTime = expirationDateTime;
        this.email = email;
        this.userId = userId;
    }

    get isTokenExpired(): boolean {
        return this.expirationDateTime === null || !(this.expirationDateTime.valueOf() > new Date().valueOf());
    }
}

export function getLoginData(token: string): LoginData | null {
    let decoded: any;

    try {
        decoded = decode(token);
    } catch(e) {
        return null;
    }

    if (!decoded) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return new LoginData(decoded.type, date, decoded.email, decoded.userId);
}
