import React, { memo } from "react";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Popper from "@material-ui/core/Popper";
import Avatar from '@material-ui/core/Avatar';
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import FocusLogo from '../../assets/images/focus-logo-trademarked.svg';
import PasswordChangeModal from '../PasswordManagement/PasswordChangeModal';
import { openPasswordChangeModal } from '../../containers/AppFrame/actions';
import { setFocusVersion } from '../../containers/App/actions';
import { FocusVersion } from "../../containers/App/types";

import UserProfileModal from './UserProfileModal';
import { makeSelectFocusVersion, makeSelectIsEidexStaff } from "../../containers/App/selectors";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    toolbar: {
        background: theme.palette.secondary.main,
        display: "flex",
        padding: "0px"
    },
    logo: {
        width: "200px",
        height: "100%",
        cursor: "pointer",
        padding: "12px 16px"
    },
    tab: {
        padding: "12px 16px 12px 16px",
        borderLeft: `2px solid ${theme.palette.accent.main}`,
        display: "flex",
        cursor: "pointer",

        "&:hover": {
            background: theme.palette.accent.main,

            // This changes the background of the account initials on userContainer hover
            "& $avatar": {
                background: theme.palette.secondary.main
            }
        }
    },
    innerTab: {
        margin: "auto"
    },
    avatar: {
        background: theme.palette.primaryFade.main,
        color: "white",
        border: "3px solid white"
    }
}));

interface Props {
    isEidexStaff: boolean,
    focusVersion: FocusVersion,
    userInitials: string,
    logout: () => void,
    openPasswordChangeModal: () => void,
    setFocusVersion: (version: FocusVersion) => void
};

const AppHeader: React.FC<Props> = (props) => {
    const classes = useStyles();

    const { isEidexStaff, userInitials, focusVersion, logout, openPasswordChangeModal, setFocusVersion } = props;

    const [open, setOpen] = React.useState(false);
    const [showProfile, setShowProfile] = React.useState(false);
    const anchorRef = React.useRef(null);

    return (
        <AppBar position="sticky">
            <PasswordChangeModal />
            <UserProfileModal show={showProfile} closeModal={() => {setShowProfile(false)}}/>
            <Toolbar className={classes.toolbar}>
                <img src={FocusLogo} className={classes.logo} alt="Focus Logo" onClick={() => window.location.href = process.env.REACT_APP_PORTAL_URL as string}/>
                <div style={{marginLeft: "auto", display: "flex"}}>
                    {isEidexStaff &&
                        <div className={classes.tab} onClick={() => window.location.href = "https://qlikview.eidexinsights.com/QlikView"}>
                            <Typography className={classes.innerTab}>QV Access</Typography>
                        </div>
                    }
                    <div className={classes.tab} onClick={() => setFocusVersion(focusVersion === 2 ? 3 : 2)}>
                        <Typography className={classes.innerTab}>{`Go to Focus ${focusVersion === 2 ? 3 : 2}.0`}</Typography>
                    </div>
                    <div className={classes.tab} onClick={() => setOpen((prevOpen) => !prevOpen)} ref={anchorRef}>
                        <Avatar className={`${classes.innerTab} ${classes.avatar}`}>{userInitials}</Avatar>
                    </div>
                </div>

                {/* The dropdown menu */}
                <Popper open={open} transition disablePortal anchorEl={anchorRef.current}>
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper>
                                <ClickAwayListener onClickAway={() => setOpen(false)}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow">
                                        <MenuItem onClick={(e) => {setShowProfile(true); setOpen(false);}}> Profile</MenuItem>
                                        <MenuItem onClick={(e) => {openPasswordChangeModal(); setOpen(false);}}> Change Password</MenuItem>
                                        <MenuItem onClick={(e) => {logout(); setOpen(false);}}> Logout</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Toolbar>
        </AppBar>
    );
}

const mapStateToProps = createStructuredSelector({
    isEidexStaff: makeSelectIsEidexStaff(),
    focusVersion: makeSelectFocusVersion()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openPasswordChangeModal: () => dispatch(openPasswordChangeModal()),
    setFocusVersion: (version: FocusVersion) => dispatch(setFocusVersion(version))
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
);

export default withConnect(memo(AppHeader));