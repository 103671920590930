import React, { useEffect, Suspense } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Route } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { useInjectSaga } from '../../utils/injectSaga';
import AppFrame from '../AppFrame';
import FocusFrame from '../FocusFrame';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
    makeSelectAlert,
    makeSelectCheckingSession,
    makeSelectHasActiveSession
} from '../App/selectors';

import saga from './saga';
import { getCurrentSession, clearAlert } from './actions';
import { AlertDetails } from './types';
import PrivateRoute from './PrivateRoute';

const AppWrapper = styled.div`
	margin: 0 auto;
	display: flex;
	min-height: 100%;
	flex-direction: column;
`;

type Props = {
    readonly alertDetails: AlertDetails,
    readonly isCheckingSession: boolean,
    readonly hasActiveSession: boolean
    readonly clearAlert: () => void,
    readonly getCurrentSession: () => void
};

const key = 'app';
const App: React.FC<Props> = (props) => {
    useInjectSaga(key, saga);
    const alert = useAlert();

    const {
        alertDetails,
        clearAlert,
        getCurrentSession,
        isCheckingSession,
        hasActiveSession
    } = props;

    useEffect(() => {
        if(alertDetails.show) {
            alert.show(alertDetails.message, {
                type: alertDetails.type,
                onClose: clearAlert
            });
        }
    }, [alert, alertDetails, clearAlert]);

    useEffect(() => {
        getCurrentSession();
    }, [getCurrentSession]);

    return (
        isCheckingSession
            ? <LoadingIndicator style={{margin: "13vh 0 0 calc(50vw - 40px)", width: "80px", height: "80px"}}/>
            : <AppWrapper>
                <Helmet
                    titleTemplate="%s - Focus"
                    defaultTitle="Eidex Focus"
                >
                    <meta name="description" content="Eidex Focus" />
                </Helmet>
                <Suspense fallback={<LoadingIndicator />}>
                    {(hasActiveSession) && <AppFrame />}
                    <Switch>
                        <PrivateRoute exact path={"/"} component={FocusFrame} />
                        <Route path="/login" component={() => {
                            window.location.replace(`${process.env.REACT_APP_PORTAL_URL}/login`);
                            return null;
                        }} />
                        <PrivateRoute path="" component={FocusFrame} />
                    </Switch>
                </Suspense>
            </AppWrapper>
	);
}

const mapStateToProps = createStructuredSelector({
    alertDetails: makeSelectAlert(),
    isCheckingSession: makeSelectCheckingSession(),
    hasActiveSession: makeSelectHasActiveSession()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearAlert: () => dispatch(clearAlert()),
    getCurrentSession: () => dispatch(getCurrentSession())
});

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps
);

export default withConnect(App);