import React from 'react';
import { Route, Redirect, RouteProps} from 'react-router-dom';

import AuthService from '../../services/AuthService';
import AccessWrapper from './AccessWrapper';

interface Props extends RouteProps {
    hasAccess?: boolean | null
}

const PrivateRoute: React.FC<Props> = (props) => {
    const { hasAccess, component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={props => AuthService.isLoggedIn
                ? <AccessWrapper {...props} component={component} hasAccess={hasAccess} />
                : <Redirect to={{pathname: "/login", state: { from: props.location }}} />
            }
        />
    );
}

export default PrivateRoute;