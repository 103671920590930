import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { createStructuredSelector } from 'reselect';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from '@material-ui/core/DialogContent';

import { makeSelectCurrentUser, makeSelectAccessLevelName, makeSelectCsmName, makeSelectInitials, makeSelectLicensesLabel } from '../../../containers/App/selectors';
import { Credential, CredentialField } from '../../../containers/App/types';
import CredentialSection from './CredentialSection';
import LabeledText from './LabeledText';
import ModalDialog from '../../ModalDialog';

interface Props {
    show: boolean,
    closeModal: () => void,
    currentUser: {
        firstName: string,
        lastName: string,
        email: string,
        prismCredentials: Credential[],
        focusCredentials: Credential[]
    },
    accessLevel: string,
    csmName: string,
    userInitials: string,
    licensesLabel: string
};

const logoSize: string = "11.5rem";

const useStyles = makeStyles(theme => ({
    profile: {
        color: "white",
        background: theme.palette.secondary.main,
        padding: "10px",
        width: logoSize,
        height: logoSize,
        fontSize: "5rem",
        position: "absolute"
    },
    firstRowGrid: {
        marginLeft: "13.5rem",
        width: `calc(100% - ${logoSize} - 48px)`
    }
}));


const UserProfileModal: React.FC<Props> = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { prismCredentials, focusCredentials, firstName, lastName, email } = props.currentUser;
    const { show, closeModal, accessLevel, csmName, userInitials, licensesLabel } = props;

    const removeNullCreds = (cred: Credential) => (Object.keys(cred) as CredentialField[]).some( (key: CredentialField) => cred[key] !== null);

    return (
        <ModalDialog
            open={show}
            onClose={closeModal}
            title={"User Profile"}
            fullWidth={true}
            style={{background: theme.palette.secondary.main, color: "white"}}
            maxWidth={"md"}
            topRightClose={true}
        >
            <DialogContent dividers>
                <Grid container>
                    <Avatar className={classes.profile}>{userInitials}</Avatar>
                    <Grid item container className={classes.firstRowGrid}>
                        <Grid item xs={12} sm={6}>
                            <LabeledText label={"First Name"} value={firstName}/>
                            <LabeledText label={"Last Name"} value={lastName}/>
                            <LabeledText label={"Email"} value={email}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabeledText label={"Client Service Manager"} value={csmName}/>
                            <LabeledText label={"Licenses"} value={licensesLabel}/>
                            <LabeledText label={"User Access"} value={accessLevel}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{paddingTop: "20px"}}>
                        <CredentialSection prismCredentials={prismCredentials.filter(removeNullCreds)} focusCredentials={focusCredentials.filter(removeNullCreds)}/>
                    </Grid>
                </Grid>
            </DialogContent>
        </ModalDialog>
    );
}

const mapStateToProps = createStructuredSelector({
    currentUser: makeSelectCurrentUser(),
    accessLevel: makeSelectAccessLevelName(),
    csmName: makeSelectCsmName(),
    userInitials: makeSelectInitials(),
    licensesLabel: makeSelectLicensesLabel()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps
);

export default withConnect(UserProfileModal);