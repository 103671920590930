import produce from 'immer';

import { CredentialField } from '../App/types';

import { OPEN_PASSWORD_CHANGE_MODAL, CLOSE_PASSWORD_CHANGE_MODAL, UPDATE_USER_CRED_LABELS } from './constants';
import { InitialState } from './types';

export const initialState: InitialState = {
    passwordChangeModalIsOpen: false,
    userPrismCredLabels: {},
    userFocusCredLabels: {}
};

const appFrameReducer = (state = initialState, action: any) =>
    produce(state, draft => {
        switch (action.type) {
            case OPEN_PASSWORD_CHANGE_MODAL: {
                draft.passwordChangeModalIsOpen = true;
                break;
            }
            case CLOSE_PASSWORD_CHANGE_MODAL: {
                draft.passwordChangeModalIsOpen = false;
                break;
            }
            case UPDATE_USER_CRED_LABELS: {
                let credType = action.credType === "prism" ? "userPrismCredLabels" : "userFocusCredLabels";

                // For each CredentialField in userPrismCredLabels or userFocusCredLabels
                Object.keys(action.options).forEach((key: string) => {

                    // Determine which field it belongs to
                    const credField: CredentialField = key.replace(/_[0-9]/g, "") as CredentialField;

                    // Add an entry for a given entity id with the corresponding name
                    const fieldObj: {id: string, name: string} = action.options[key][0];

                    draft[credType] = {
                        ...draft[credType], [credField]: {
                            ...draft[credType][credField], [fieldObj.id]: fieldObj.name
                        }
                    }
                })
                break;
            }
            default: {
                break;
            }
        }
    });

export default appFrameReducer;