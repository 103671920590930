import React from 'react';
import { ReactReduxContext } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getInjectors from './reducerInjectors';

export const injectReducer = (key: string, reducer: (state: any, action: any) => any) => (WrappedComponent: any) => {
    class ReducerInjector extends React.Component {
        static WrappedComponent = WrappedComponent;
        static contextType = ReactReduxContext;
        static displayName = `withReducer(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

        constructor(props: any, context: any) {
            super(props, context);

            getInjectors(context.store).injectReducer(key, reducer);
        }

        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    return hoistNonReactStatics(ReducerInjector, WrappedComponent);
};

export const useInjectReducer = (key: string, reducer: (state: any, action: any) => any) => {
    const context = React.useContext(ReactReduxContext);
    React.useEffect(() => {
        getInjectors(context.store).injectReducer(key, reducer);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default injectReducer;
