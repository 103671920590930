import React, { useEffect, useState } from "react";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useTheme } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

import { makeSelectUserPrismCredLabels, makeSelectUserFocusCredLabels } from '../../../containers/AppFrame/selectors';
import { Credential, CredentialField, CredentialType } from '../../../containers/App/types';
import { CredLabels } from '../../../containers/AppFrame/types';
import { getUserCredLabels } from '../../../containers/AppFrame/actions';
import { IntermediateLabels } from '../../../containers/AppFrame/constants';
import LoadingIndicator from '../../LoadingIndicator';

import LabeledText from './LabeledText';

interface Props {
    prismCredentials: Credential[],
    focusCredentials: Credential[],
    knownFocusCredLabels: CredLabels,
    knownPrismCredLabels: CredLabels,
    readonly getUserCredLabels: (credType: CredentialType, creds: Credential[]) => void
};

const CredentialSection: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { prismCredentials, focusCredentials, knownFocusCredLabels, knownPrismCredLabels, getUserCredLabels } = props;

    const getCredCols = (credType: CredentialType, cred: Credential, credNum: number, nonNullCount: number) => {
        const credFieldLabels: {[key in CredentialField]: string} = {state: "State", intermediate: IntermediateLabels[cred.state || ""] || "Region", district: "District", building: 'Building', teacher: "Teacher", section: "Section", student: "Student"}
        const credLables: CredLabels = credType === 'prism' ? knownPrismCredLabels : knownFocusCredLabels;

        return (Object.keys(cred) as Array<CredentialField>).map( (credField: CredentialField) => {

            // If user has a value for the current cred field
            if (cred[credField]) {
                const entityKeyObj = credLables[credField] || {};
                const entityKey = cred[credField] || "";
                return <LabeledText key={`${credType}_${credNum}_${credField}`} style={{width: `${(1/nonNullCount) * 100}%`}} label={credFieldLabels[credField] || ""} value={entityKeyObj[entityKey]}/>
            }

            return <React.Fragment key={`${credType}_${credNum}_${credField}`}></React.Fragment>;
        })
    }

    // Get the max number of non-null field values in a single cred for a given array of creds
    const getNonNullCount = (creds: Credential[]) => {
        return Math.max(
            ...creds.map(cred => {
                let numNonNull = 0;
                (Object.keys(cred) as Array<CredentialField>).forEach( (key: CredentialField) => {
                    if (cred[key] !== null) {
                        numNonNull++;
                    }
                })
                return numNonNull;
            })
        );
    }

    const getCredRow = (credType: CredentialType) => {
        const creds : Credential[] = credType === 'prism' ? prismCredentials : focusCredentials;
        const nonNullCount = getNonNullCount(creds);

        return <>
            <Typography style={{color: theme.palette.secondary.main, fontWeight: 700}}>{credType === 'prism' ? "PRISM" : "FOCUS"} Data Access</Typography>
            {creds.map( (cred: Credential, i: number) =>
                <div key={`${credType}_${i}`} style={{display: "flex"}}>
                    {getCredCols(credType, cred, i, nonNullCount)}
                </div>
            )}
        </>
    };

    useEffect(() => {
        let isLoaded = true;

        // If we do not have prism cred labels
        if (prismCredentials.length && Object.keys(knownPrismCredLabels).length === 0) {
            isLoaded = false;
            getUserCredLabels('prism', prismCredentials);
        }

        // If we do not have focus cred labels
        if (focusCredentials.length && Object.keys(knownFocusCredLabels).length === 0) {
            isLoaded = false;
            getUserCredLabels('focus', focusCredentials);
        }

        setIsLoading(!isLoaded);

    }, [prismCredentials, focusCredentials, knownPrismCredLabels, knownFocusCredLabels, getUserCredLabels]);

    return (isLoading
        ? <><Typography style={{textAlign: "center", paddingTop: "15px"}}>Loading Data Access...</Typography>
            <LoadingIndicator style={{width: "30px", height: "30px", margin: "15px auto"}}></LoadingIndicator></>

        : <>{!!prismCredentials.length && getCredRow('prism')}
            {!!focusCredentials.length && <div style={{paddingTop: prismCredentials.length ? "20px" : "0"}}>{getCredRow('focus')}</div>}</>
    );
}

const mapStateToProps = createStructuredSelector({
    knownPrismCredLabels: makeSelectUserPrismCredLabels(),
    knownFocusCredLabels: makeSelectUserFocusCredLabels()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getUserCredLabels: (credType: CredentialType, creds: Credential[]) => dispatch(getUserCredLabels(credType, creds))
});

export default connect(mapStateToProps, mapDispatchToProps)(CredentialSection);