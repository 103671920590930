import React from 'react';
import { ReactReduxContext } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getInjectors from './sagaInjectors';

export const injectSaga = (key: string, saga: any, mode?: any) => (WrappedComponent: any) => {
    class InjectSaga extends React.Component<any, any> {
        static WrappedComponent = WrappedComponent;
        static contextType = ReactReduxContext;
        static displayName = `withSaga(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

        public injectors: any;
        constructor(props: any, context: any) {
            super(props, context);

            this.injectors = getInjectors(context.store);
            this.injectors.injectSaga(key, { saga, mode }, this.props);
        }

        componentWillUnmount() {
            this.injectors.ejectSaga(key);
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return hoistNonReactStatics(InjectSaga, WrappedComponent);
};

export const useInjectSaga = (key: string, saga: any, mode?: any) => {
    const context = React.useContext(ReactReduxContext);
    React.useEffect(() => {
        const injectors = getInjectors(context.store);
        injectors.injectSaga(key, { saga, mode });

        return () => {
            injectors.ejectSaga(key);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default injectSaga;