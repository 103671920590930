import { createMuiTheme } from '@material-ui/core/styles';

// app theme
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2196f3'
        },
        primaryFade: {
            main: '#50a5dc'
        },
        secondary: {
            main: '#1b356b'
        },
        accent: {
            main: "#3a4f79"
        },
        error: {
            main: '#d32f2f'
        },
        success: {
            main: '#43a047'
        },
        info: {
            main: '#1976d2'
        },
        background: {
            default: '#f2f2f2'
        },
        cancel: {
            main: "#dc004e"
        },
        headerHeight: {
            main: "64px"
        },
        menuWidth: {
            main: "235px"
        }
    }
});

// Use these interface declorations to add custome colors to MUI theme palette
declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        primaryFade: Palette['primary'];
        accent: Palette['primary'];
        cancel: Palette['primary'];
        headerHeight: Palette['primary'];
        menuWidth: Palette['primary'];
    }

    interface PaletteOptions {
        primaryFade: PaletteOptions['primary'];
        accent: PaletteOptions['primary'];
        cancel: PaletteOptions['primary'];
        headerHeight: PaletteOptions['primary'];
        menuWidth: PaletteOptions['primary'];
    }
}

export default theme;