import React, { memo } from "react";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';

import { closePasswordChangeModal } from '../../containers/AppFrame/actions';
import { makeSelectPasswordChangeModalIsOpen } from '../../containers/AppFrame/selectors';
import ModalDialog from '../ModalDialog';

import PasswordUpdatePane from './PasswordUpdatePane';

interface Props {
    passwordChangeModalIsOpen: boolean,
    closePasswordChangeModal: () => void
};

const PasswordChangeModal: React.FC<Props> = (props) => {
    const theme = useTheme();
    const { passwordChangeModalIsOpen, closePasswordChangeModal } = props;

    return (
        <ModalDialog
            open={passwordChangeModalIsOpen}
            onClose={closePasswordChangeModal}
            title={"Change Password"}
            style={{background: theme.palette.secondary.main, color: "white"}}
            fullWidth={false}
            maxWidth={"sm"}
            topRightClose={true}
        >
            <DialogContent dividers style={{padding: "8px 0px"}}>
                <PasswordUpdatePane />
            </DialogContent>
        </ModalDialog>
    );
}

const mapStateToProps = createStructuredSelector({
    passwordChangeModalIsOpen: makeSelectPasswordChangeModalIsOpen()
});;

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closePasswordChangeModal: () => dispatch(closePasswordChangeModal())
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
);

export default withConnect(memo(PasswordChangeModal));
